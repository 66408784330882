import {
	ApiNetworkProvider,
} from "@elrondnetwork/erdjs-network-providers";
import {
	Address
} from "@elrondnetwork/erdjs";

export class MyApiNetworkProvider extends ApiNetworkProvider {

    async getAccountContracts(address: string): Promise<AccountContract[]> {
        const response = await this.doGetGeneric(`accounts/${address}/contracts`);
        const accountContracts = response.map((item: any) => AccountContract.fromApiHttpResponse(item));
        return accountContracts;
    }

}

export class AccountContract {
	address: Address = new Address();
	deployTxHash = "";
	timestamp = 0;

    static fromApiHttpResponse(payload: any): AccountContract {
        const result = new AccountContract();

        result.address = new Address(payload.address || "");
        result.deployTxHash = payload.deployTxHash || "";
        result.timestamp = payload.timestamp;

        return result;
    }
}