import * as React from 'react';
import { useState, useEffect } from 'react';
import { CPopover,CModal,CButton,CModalHeader,CModalBody,CModalTitle,CModalFooter,CFormInput } from '@coreui/react';
import {
	ProxyNetworkProvider,
	ApiNetworkProvider,
} from '@elrondnetwork/erdjs-network-providers';
import { useNavigate } from 'react-router-dom';
import ContractCard from 'components/ContractCard';
import { routeNames } from 'routes';
import Actions from './Actions';
import TopInfo from './TopInfo';
import Transactions from './Transactions';
import {
	useGetNetworkConfig,
  useGetAccountInfo
} from "@elrondnetwork/dapp-core/hooks";
import {
	MyApiNetworkProvider,
} from "../../MyApiNetworkProvider";

const Dashboard = () => {

  const navigate = useNavigate();

	const [contracts, setContracts] = useState(Array());

	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress, {
		timeout: 10000,
	});

	const { address } = useGetAccountInfo();

  const [visible, setVisible] = useState(false);
  const goTo = (e : React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(routeNames.statistics);
  };

  useEffect(() => {
    getContracts();
  }, []);

  const getContracts = async () => {

    const contracts = await apiNetworkProvider.getAccountContracts(address);
    setContracts(contracts);
    console.log(contracts);

  }
  class FileInput extends React.Component {
		// eslint-disable-next-line @typescript-eslint/ban-types
		constructor(props: Object) {
			super(props);
			this.uploadFile = this.uploadFile.bind(this);
		}

		uploadFile(event: React.ChangeEvent<HTMLInputElement>) {
			if (!event.target.files) {
				return;
			}

			const file = event.target.files[0];

			if (file) {
				const data = new FormData();
				const reader = new FileReader();
				reader.onload = async (e) => {
					if (!e.target?.result) {
						return;
					}
					const text = e.target.result;
				};
				reader.readAsText(file);
			}
		}

		render() {
			return (
				<span>
					<input
						type="file"
						name="myFile"
						onChange={this.uploadFile}
						accept=".csv"
					/>
				</span>
			);
		}
	}
  return (
    <div>
      <div className="flex flex-col mt-5">
        <div className="d-flex flex-row justify-content-around">
          <h1 className="text-2xl font-bold text-center">My Contracts</h1>
          <button
            className="btn btn-primary px-4 py-2"
            onClick={() => setVisible(!visible)}
          >
            + Deploy New <br />
            Contract
          </button>
        </div>
        <div className="flex flex-col mt-5 container">
          {contracts.length ? 
            contracts.map(contract => (

              <ContractCard
                address={contract.address.toString()}
                name="ElrondBulk"
              />
            ))
           : (
            <h5 className='text-center contract'>You don't have any Contract Deployed</h5>
          )}
        </div>
      </div>
      <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
            <CModalTitle>Deploy Contract</CModalTitle>
        </CModalHeader>
        <CModalBody>
        <h5 className='mt-2 mb-3'>WASM file</h5>
        <FileInput />
        <h5 className='mt-4 mb-3'>ABI file</h5>
        <FileInput />
        </CModalBody>
        <CModalFooter className='mt-2'>
            <CButton color="secondary" onClick={() => setVisible(false)}>
            Close
            </CButton>
            <CButton color="primary">Send</CButton>
        </CModalFooter>
        </CModal>
    </div>
  );
};

export default Dashboard;
