import {  CPopover } from "@coreui/react";
import * as React from "react";
import {  useNavigate } from "react-router-dom";
import {  routeNames } from "routes";
import {  sendTransactions } from "@elrondnetwork/dapp-core/services";
import {  refreshAccount } from "@elrondnetwork/dapp-core/utils";
import {
	 faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {  FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ContractProps = {
	address: string;
	name: string;
};
const ContractCard = ({ address, name }: ContractProps) => {
	const shortAddress = () => {
		return (
			address.substring(0, 5) + "..." + address.substring(address.length - 9)
		);
	};

	const navigate = useNavigate();

	const sendClaimRewards = async () => {
		const tx = {
			value: "0",
			data: "ClaimDeveloperRewards",
			receiver: address,
			gasLimit: "50000000",
		};
		await refreshAccount();

		const { sessionId /*, error*/ } = await sendTransactions({
			transactions: tx,
			transactionsDisplayInfo: {
				processingMessage: "Processing Ping transaction",
				errorMessage: "An error has occured during Ping",
				successMessage: "Ping transaction successful",
			},
			redirectAfterSign: false,
		});
	};

	const goTo = (e: React.FormEvent<HTMLButtonElement>) => {
		e.preventDefault();
		console.log("goTo");
		navigate(routeNames.statistics);
	};

	return (
		<div className="d-flex flex-row justify-content-between w-100 py-3 px-5 contract-card align-items-center my-3">
			<div className="contract-el">
				<p className="title">
					<b>{name}</b>
				</p>
				<CPopover content={address} placement="bottom" trigger="hover">
        
        <button className='btn px-0 whitetext' onClick={() => {navigator.clipboard.writeText(address)}} >{shortAddress()} <FontAwesomeIcon icon={faCopy} className='whitetext' /></button>
				</CPopover>


			</div>
			<div className="contract-el">
				<p>
					<b>Developer Rewards</b>
				</p>
				<p>0.0096 EGLD</p>
			</div>

			<div>
				<button className="btn btn-secondary mx-2">Claim Rewards</button>
				<button className="btn btn-primary" onClick={goTo}>
					Statistics
				</button>
			</div>
		</div>
	);
};

export default ContractCard;
