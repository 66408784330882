import * as React from 'react';
/*
    DEPLOYED FROM: data
    NUM CONTRACT CALLS
    NUM TRANSFERS
    N TOKENS


*/
const Statistics = () => {
    return (
      <div className="flex flex-col mt-5">
        <div className="d-flex flex-row justify-content-around">
          <h1 className="text-2xl font-bold text-center">Statistics</h1>
          <span></span>
        </div>
        <div className="flex flex-row mt-5 container stats">
          <div className="d-flex flex-row justify-content-around stats">
            <div className="d-flex flex-column justify-content-between py-4 px-5 contract-card align-items-center my-3">
              <h2 className="font-weight-bold ">Deployed From</h2>
              <br />
              <h5 className="text-center">
                256 days 19 hrs ago <br />
                (Nov 14, 2021 13:38:48 PM UTC)
              </h5>
            </div>
            <div className="d-flex flex-column justify-content-between py-4 px-5 contract-card align-items-center my-3">
              <h2 className="font-weight-bold">Contract Calls</h2>
              <br />
              <h5>411</h5>
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-5 container stats">
          <div className="d-flex flex-row justify-content-around stats">
            <div className="d-flex flex-column justify-content-between py-3 px-5 contract-card align-items-center my-3">
              <h2 className="font-weight-bold">Number of Transfers</h2>
              <br />
              <h5>20000</h5>
            </div>
            <div className="d-flex flex-column justify-content-between py-3 px-5 contract-card align-items-center my-3">
              <h2 className="font-weight-bold">Number of Tokens</h2>
              <br />
              <h5>10</h5>
            </div>
          </div>
        </div>
        <div className="flex flex-row mt-5 container stats">
          <div className="d-flex flex-row justify-content-between py-3 contract-card align-items-center px-5 mx-5 stats">
            <span className="badge badge-success">UPGRADEABLE</span>
            <span className="badge badge-success">READABLE</span>
            <span className="badge badge-success">PAYABLE</span>
            <span className="badge badge-success">PAYABLE BY SC</span>
          </div>
        </div>
      </div>
    );
};

export default Statistics;